/* carousel slider style start */

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slick-slide *:focus{
  outline: none;
}
/* carousel slider style end */